.stripe-integration-modal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 3.5rem);
    max-width: 595px;
    height: 100%;
    font-family: 'Mulish', sans-serif !important;
}

.stripe-integration-modal .modal-dialog .modal-content {
    border-radius: 4.8px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    height: auto;
    box-shadow: none;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-header {
    background: #ffffff;
    border-bottom: 1px solid #DEE2E6;
    line-height: 24px;
    font-size: 20px;
    padding: 10px 16px;
    border-top-left-radius: 4.8px;
    border-top-right-radius: 4.8px;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-header .closeIcon {
    line-height: 20px;
    cursor: pointer;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-header .modal-title {
    line-height: 120%;
    font-size: 20px;
    color: #212529;
    font-weight: 700;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-body {
    height: fit-content;
    background-color: #fff;
    font-size: 16px;
    min-height: 5rem;
    max-height: calc(100vh - 180px);
    padding: 20px 25px 40px 17px;
    overflow: auto;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-body .link {
    color: #0973BA;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-footer {
    background: #fff;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-footer .btn-stripe {
    background: #635BFF;
    border: 1px solid #635BFF;
    border-radius: 2px !important;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
    padding: 7px 13px;
    height: 38px;
}

.stripe-integration-modal .modal-dialog .modal-content .modal-footer .stripe-logo {
    margin-left: 5px;
    margin-bottom: 2px;
}
